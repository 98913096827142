import * as server from "./server";
import {_wpnGetCanonicalUrl, _wpnGetPageType} from "./product_detection";
import {_wpnGetCurrentExperiments} from "./experiments";
import {_wpnRemoveUtmsFromUrl} from "./chat";
import {closeAndRemove} from "./ui";
import * as browser from "./browser";

const CACHE_DURATION = 600;

const POPUP_EVENT = 10;
const BANNER_EVENT = 11;

const BANNER_ENDPOINT = '/banner/index';
const BANNER_DAYS_TO_HIDE = 30;

const POPUP_ENDPOINT = '/popup/index';
const POPUP_DAYS_TO_HIDE = 1;

const BANNER_TABLE = "wpnb";
const POPUP_TABLE = "wpnp";
const WIDGET_USED_SUFFIX = "us";

const DEVICE_DESKTOP = 3;

//Creates a widget availability table and merges it if already exists
//taking into account if one widget has been disabled
function setAvailabilityWidgetTable(tableName, widgetAvailability) {
  if (window.sessionStorage) {
    if (widgetAvailability !== undefined && window.sessionStorage) {
      let existingData = sessionStorage.getItem(tableName);
      if (existingData !== null) {
        let existingTable = JSON.parse(existingData);
        for (let item in widgetAvailability) {
          if (Object.prototype.hasOwnProperty.call(widgetAvailability, item)) {
            if (existingTable[item] !== undefined && typeof existingTable[item] === 'object' && existingTable[item].ok === false) {
              widgetAvailability[item].ok = false;
            }
          }
        }
      }
      sessionStorage.setItem(tableName, JSON.stringify(widgetAvailability));
    }
  }
}

//Returns the cached widget table by name from session storage
function getCachedWidgetTable(table) {
  let cachedTable = null;
  if (window.sessionStorage) {
    let data = sessionStorage.getItem(table);
    if (data !== null) {
      cachedTable = JSON.parse(data);
    }
  }

  return cachedTable;
}


//Marks the identified widget as inactive.
function markWidgetInactive(table, id) {
  let items = getCachedWidgetTable(table);
  if (items != null && typeof items === "object" && items[id] !== undefined && typeof items[id] === 'object') {
    items[id].ok = false;
    sessionStorage.setItem(table, JSON.stringify(items))
  }
}

//Checks if the widget identified by id in the table is active
function widgetIsCancelled(table, id) {
  let items = getCachedWidgetTable(table);
  if (id !== null && items != null && typeof items === "object" && items[id] !== undefined) {
    return items[id].ok === false;
  }

  return false
}

//If the widget was clicked or close, we set it as used with a cookie. That cookie in turn has the last updated at value for the widget
function widgetIsUsed(id, updatedAt) {
  let widgetVersionUsed = browser._wpnGetCookie(id + WIDGET_USED_SUFFIX);
  let widgetUsed = !(widgetVersionUsed == null || widgetVersionUsed === "") && parseInt(widgetVersionUsed, 10) >= updatedAt;
  //if the cookie is outdated remove it
  if (parseInt(widgetVersionUsed, 10) < updatedAt) {
    browser._wpnDeleteCookie(id + WIDGET_USED_SUFFIX);
  }
  return widgetUsed;
}

//Returns the widget (or null) that applies for the current url by looking at the specified table
function getIdOfCachedWidgetForURL(table, url) {
  let items = getCachedWidgetTable(table);
  if (items != null && typeof items === "object") {
    for (let id in items) {
      if (Object.prototype.hasOwnProperty.call(items, id) && browser.appliesForCurrentURL(items[id].display, items[id].pages, url)) {
        return id;
      }
    }
  }

  return null;
}

//// BANNER

//Fetches the widget data for the client id specified
function fetchWidgetData(clientId, endpoint) {
  let data = "idClient=" + clientId;
  //Allows the referrer to use this less restrictive flag
  let headers = new Headers();
  headers.append("Access-Control-Request-Headers", "x-wpn-ref");
  headers.append("x-wpn-ref", window.location.href);
  return server.retrieveFromWPN(endpoint, data, 'no-referrer-when-downgrade', headers);
}

//Gets the widget settings for the client id. It first tries to retrieve data from a session storage cache. If not found
//it requests the data to the TP server.
function getWidgetSettings(clientId, widgetTable, endpoint) {
  let computedWidgetId = getIdOfCachedWidgetForURL(widgetTable, window.location.href);
  let cachedWidgetHTML = sessionStorage.getItem(computedWidgetId);
  let table = getCachedWidgetTable(widgetTable);
  let expirationKey = widgetTable + "-" + "exp";
  let currentTimestamp = Math.floor(Date.now() / 1000);
  let expirationTime = sessionStorage.getItem(expirationKey);

  //If there's no widget table, or the widget not in cache, then fetch it to the server.
  if (table === null || (computedWidgetId !== null && cachedWidgetHTML === null) || (expirationKey == null || currentTimestamp >= expirationTime)) {
    return fetchWidgetData(clientId, endpoint).then(function (widgetData) {
      //Set expire date for the cache
      sessionStorage.setItem(expirationKey, currentTimestamp + CACHE_DURATION);

      if (widgetData) {
        if (widgetData.available !== undefined && typeof widgetData.available === 'object') {
        setAvailabilityWidgetTable(widgetTable, widgetData.available);
      }

        if (widgetData.id !== undefined && widgetData.html !== undefined) {
          sessionStorage.setItem(widgetData.id, widgetData.html);
        }
      }

      return widgetData;
    });
  }

  //If there are items in the table cache, and the widget is found in the cache, serve it from there
  if (Object.keys(table).length > 0 && computedWidgetId !== null && cachedWidgetHTML !== null) {
    let updatedAtCached = table[computedWidgetId].updatedAt;
    return Promise.resolve({
      id: computedWidgetId, html: cachedWidgetHTML, updatedAt: updatedAtCached,
      showInMobile: table[computedWidgetId].showInMobile
    });
  }

  //No data present, then resolve to no data to be displayed. The render function will ignore it.
  return Promise.resolve({});
}

//Returns the days to hide for the widget. Might return false if no data is set.
function getWidgetDaysToHide(table, id) {
  let items = getCachedWidgetTable(table);
  if (id !== null && items != null && typeof items === "object" && items[id] !== undefined) {
    return items[id].daysToHide;
  }

  return false;
}


/**
 * Returns the delay in seconds to display the popup
 * @param table
 * @param id
 * @returns {*|boolean}
 */
function getPopupDelay(table, id) {
  let items = getCachedWidgetTable(table);
  if (id !== null && items != null && typeof items === "object" && items[id] !== undefined) {
    return items[id].delayInSeconds;
  }
  return false;
}

//Renders the banners for the client
export function renderBanners(clientId) {
  if (clientId !== null) {
    getWidgetSettings(clientId, BANNER_TABLE, BANNER_ENDPOINT).then(function (banner) {
      if (banner && banner.html !== undefined && !widgetIsCancelled(BANNER_TABLE, banner.id) && !widgetIsUsed(banner.id, banner.updatedAt)) {
        renderBanner(banner, clientId);
      }
    });
  }
}

//Renders a banner object in the website of the client. It also binds the behavior expected for the actions
//of the banner
function renderBanner(banner, clientId) {
  const wpnPageType = _wpnGetPageType();
  const canonicalUrl = _wpnGetCanonicalUrl();
  const experiments = _wpnGetCurrentExperiments();
  const referrer = _wpnRemoveUtmsFromUrl(document.referrer);

  let id = extractNumericalIdFromWidget(banner.id)

  let body = document.getElementsByTagName('body')[0];
  body.insertAdjacentHTML('beforeend', banner.html);

  let daysToHide = getWidgetDaysToHide(BANNER_TABLE, banner.id) || BANNER_DAYS_TO_HIDE;

  //Bind close buttons
  const closeBtn = document.getElementById("wpn-close-btn");
  if (closeBtn != null) {
    closeBtn.addEventListener("click", function (e) {
      closeAndRemove("wpn-banner");
      markWidgetInactive(BANNER_TABLE, banner.id);
      browser._wpnSetCookie(banner.id + WIDGET_USED_SUFFIX, banner.updatedAt, daysToHide);
      server.trackEvent(clientId, BANNER_EVENT, 'close', null, window.location.href, null, wpnPageType, canonicalUrl,
        referrer, experiments, id);
      browser.sendEventToClientsGA('wpnBannerClose', 'WPN', 'close_wpn', 'banner');
    });
  }

  //Bind click
  const bannerClick = document.getElementById("wpn-banner-link");
  if (bannerClick != null) {
    bannerClick.addEventListener("click", function (e) {
      closeAndRemove("wpn-banner");
      browser._wpnSetCookie(banner.id + WIDGET_USED_SUFFIX, banner.updatedAt, daysToHide);
      server.trackEvent(clientId, BANNER_EVENT, 'click', null, window.location.href, null, wpnPageType, canonicalUrl,
        referrer, experiments, id);
      browser.sendEventToClientsGA('wpnBannerClicked', 'WPN', 'click_wpn', 'banner');
      browser.sendEventToClientsGA4('banner', null, null, null, "wpn_click");
    });
  }

  //Send view event to the tracker
  server.trackEvent(clientId, BANNER_EVENT, 'view', null, window.location.href, null, wpnPageType, canonicalUrl,
    referrer, experiments, id);
  browser.sendEventToClientsGA('wpnBannerView', 'WPN', 'view_wpn', 'banner');
  browser.sendEventToClientsGA4('banner', null, null, null, "wpn_view");
}


//// POPUP

export function renderPopups(clientId) {
  if (clientId !== null) {
    getWidgetSettings(clientId, POPUP_TABLE, POPUP_ENDPOINT).then(function (popup) {
      if (popup && popup.html !== undefined && !widgetIsCancelled(POPUP_TABLE, popup.id) && !widgetIsUsed(popup.id, popup.updatedAt)) {
        renderPopup(popup, clientId);
      }
    });
  }
}

//Render the popup and bind the behavior to the click/close links
function renderPopup(popup, clientId) {
  const wpnPageType = _wpnGetPageType();
  const canonicalUrl = _wpnGetCanonicalUrl();
  const experiments = _wpnGetCurrentExperiments();
  const referrer = _wpnRemoveUtmsFromUrl(document.referrer);

  let id = extractNumericalIdFromWidget(popup.id)

  let delayInSeconds = getPopupDelay(POPUP_TABLE, popup.id)

  let body = document.getElementsByTagName('body')[0];
  body.insertAdjacentHTML('beforeend', popup.html);

  let daysToHide = getWidgetDaysToHide(POPUP_TABLE, popup.id) || POPUP_DAYS_TO_HIDE;

  //closing function
  const closePopup = function (e) {
    closeAndRemove("wpn-popup-modal");
    closeAndRemove("wpn-popup-backdrop");
    markWidgetInactive(POPUP_TABLE, popup.id);
    browser._wpnSetCookie(popup.id + WIDGET_USED_SUFFIX, popup.updatedAt, daysToHide);
    server.trackEvent(clientId, POPUP_EVENT, 'close', null, window.location.href, null, wpnPageType, canonicalUrl,
      referrer, experiments, id);
    browser.sendEventToClientsGA('wpnPopupClose', 'WPN', 'close_wpn', 'popup');
  };

  //Bind close event if popup exists
  const closeModal = document.getElementById("wpn_close_banner_dialog");
  if (closeModal != null) {
    closeModal.addEventListener("click", closePopup);
  }

  //Close when entering escape key
  const handleEscKey = function (e) {
    if (e.key == 'Esc' || e.key == 'Escape') {
      document.removeEventListener('keydown', handleEscKey)
      closePopup(e);
    }
  };
  //Close when clicking outside
  const modalBackdrop = document.getElementById("wpn-popup-modal");
  if (modalBackdrop != null) {
    modalBackdrop.addEventListener("click", function (e) {
      if (e.currentTarget === e.target) {
        closePopup(e);
      }
    });
    document.addEventListener('keydown', handleEscKey);
  }

  //Bind click
  const popupClick = document.getElementById("wpn-popup-btn");
  popupClick.addEventListener("click", function (e) {
    closeAndRemove("wpn-popup-modal");
    closeAndRemove("wpn-popup-backdrop");
    browser._wpnSetCookie(popup.id + WIDGET_USED_SUFFIX, popup.updatedAt, daysToHide);
    server.trackEvent(clientId, POPUP_EVENT, 'click', null, window.location.href, null, wpnPageType, canonicalUrl,
      referrer, experiments, id);
    browser.sendEventToClientsGA('wpnPopupClicked', 'WPN', 'click_wpn', 'popup');
    browser.sendEventToClientsGA4('popup', null, null, null, "wpn_click");
  });

  setTimeout(function () {
    showPopupToUser(clientId, wpnPageType, canonicalUrl, referrer, experiments, id);
  }, delayInSeconds * 1000)

  if (browser._wpnGetDeviceType() === DEVICE_DESKTOP) {
    //Bind exit intent
    setTimeout(function () {
      document.addEventListener("mouseout", function exitIntent(evt) {
        if ((evt.toElement === null || evt.toElement === undefined) && evt.relatedTarget === null) {
          showPopupToUser(clientId, wpnPageType, canonicalUrl, referrer, experiments, id);
          document.removeEventListener("mouseout", exitIntent);
        }
      });
    }, 2000);
  } else if (popup.showInMobile) {
    //Only if coming from an ad activate
    if (urlComesFromAdCampaign() || urlComesFromExternalSite()) {
      if (WPN_DEBUG_BANNER_CLICK) {
        alert('Popup mobile debe ser activado');
      }
      addWpnPopupIntentToState();
      const element = document.createElement('a');
      element.setAttribute('href', '#wpn');
      element.style.position = 'fixed';
      element.style.top = '0';
      element.style.left = '0';
      element.style.width = '100vw';
      element.style.height = '100vh';
      element.style.backgroundColor = 'transparent';
      document.body.appendChild(element);
      element.style.zIndex = (getHighestSiblingZIndex(element) + 1).toString();
      window._alreadyCalled = false;
      element.addEventListener('click', function (event) {
        //remove the element
        if (WPN_DEBUG_BANNER_CLICK) {
          alert('Detecté un click');
        }
        if (element && element.parentNode && !window._alreadyCalled) {
          window._alreadyCalled = true;
          element.parentNode.removeChild(element);
          const clickX = event.clientX;
          const clickY = event.clientY;
          const targetElement = document.elementFromPoint(clickX, clickY);

          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
            clientX: clickX,
            clientY: clickY
          });

          targetElement.dispatchEvent(clickEvent);
        }
        return true;
      });
      window.addEventListener('popstate', function (event) {
        if (WPN_DEBUG_BANNER_CLICK) {
          alert('Detecté popstate');
        }
        if (stateHasPopupIntent()) {
          if (WPN_DEBUG_BANNER_CLICK) {
            alert('Popstate indica que debe mostrar banner');
          }
          showPopupToUser(clientId, wpnPageType, canonicalUrl, referrer, experiments, id);
          return;
        }
        return true;
      });
    }
  }
}

function getHighestSiblingZIndex(element) {
  var parent = element.parentNode;
  var children = parent.children;
  var highestIndex = 0;
  for (var i = 0; i < children.length; i++) {
    var child = children[i];
    var zIndex = Number.parseInt(
      document.defaultView.getComputedStyle(child, null).getPropertyValue("z-index"),
      10
    );
    if (zIndex > highestIndex) {
      highestIndex = zIndex;
    }
  }
  return highestIndex;
}

function addWpnPopupIntentToState() {
  let state = window.history.state || {};
  state.wpnPopupIntent = true;
  const url = window.location.href;
  window.history.replaceState(state, '', url);
  if (WPN_DEBUG_BANNER_CLICK) {
    alert('Agregando dato al state');
  }
}

function stateHasPopupIntent() {
  let state = window.history.state || {};
  return state.wpnPopupIntent;
}

function showPopupToUser(clientId, wpnPageType, canonicalUrl, referrer, experiments, id) {
  // An intent to exit has happened
  const popupModal = document.getElementById("wpn-popup-modal");
  const backdrop = document.getElementById("wpn-popup-backdrop");
  if (popupModal && backdrop){
    popupModal.classList.remove("wpn-fade");
    backdrop.classList.remove("wpn-fade");
  }
  //Send view event to the tracker
  server.trackEvent(clientId, POPUP_EVENT, 'view', null, window.location.href, null, wpnPageType, canonicalUrl,
    referrer, experiments, id);
  browser.sendEventToClientsGA('wpnPopupView', 'WPN', 'view_wpn', 'popup');
  browser.sendEventToClientsGA4('popup', null, null, null, "wpn_view");
}

function urlComesFromAdCampaign() {
  let toCheck = ['utm_source', 'utm_campaign', 'fbclid', 'gclid'];
  for (let i = 0; i < toCheck.length; i++) {
    if (urlSearchParamExists(window.location.search, toCheck[i])) {
      return true;
    }
  }
  return false;
}

function urlSearchParamExists(searchQuery, param) {
  let urlParams = new URLSearchParams(searchQuery);
  return urlParams.has(param);
}

const WPN_DEBUG_BANNER_CLICK = urlSearchParamExists(window.location.search, "wpndebugclick");

//Detects if the referrer is instagram/google/facebook
function urlComesFromExternalSite() {
  let referrer = document.referrer || '';
  let externalSites = ['instagram.com', 'facebook.com', 'google.com'];
  for (let i = 0; i < externalSites.length; i++) {
    if (referrer.includes(externalSites[i])) {
      return true;
    }
  }
  return false;
}

function extractNumericalIdFromWidget(widgetId) {
  let id = null;
  let parsedIdMatch = widgetId.match(/\w+-(\d+)/);
  if (parsedIdMatch[1] !== undefined) {
    id = parsedIdMatch[1];
  }
  return id;
}