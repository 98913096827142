/**
 * Browser utility functions
 */
export const DEVICE_PHONE = 1;
export const DEVICE_TABLET = 2;
export const DEVICE_DESKTOP = 3;

/**
 * Display type constants for widgets (chat, popup & banner)
 * @type {number}
 */
export const DISPLAY_ALL_PAGES = 10;
export const DISPLAY_ONLY_PAGES = 20;
export const DISPLAY_EXCLUDE_PAGES = 30;

//Normalize the URL by trimming whitespace and converting it to lowercase for easier matching
function normalizeURL(url) {
    return url.trim().toLowerCase().replace(/\/$/, '').replace(/,/g, '%2c');
}

//Checks if the current page matches the pages passed as parameter with the display mode
export function appliesForCurrentURL(displayOption, pages, currentUrl) {
    let matchingUrls = pages === null ? "" : pages.split(",");
    currentUrl = normalizeURL(currentUrl);

    //Declare for reuse a matching function
    function matchURLWithList(matchingUrls, valueWhenMatch, valueWhenNotMatch) {
        for (let i = 0; i < matchingUrls.length; i++) {
            let matchingUrl = normalizeURL(matchingUrls[i]);
            if (matchingUrl.indexOf("*") !== -1) {
                //If having the wildcard character, then match with substr
                let match = matchingUrl.substr(0, matchingUrl.indexOf("*"));
                if (currentUrl.includes(match)) {
                    return valueWhenMatch;
                }
            } else if (matchingUrl === currentUrl) {
                return valueWhenMatch;
            }
        }
        return valueWhenNotMatch;
    }

    if (displayOption === DISPLAY_ALL_PAGES) {
        return true;
    } else if (displayOption === DISPLAY_ONLY_PAGES) {
        return matchURLWithList(matchingUrls, true, false);
    } else if (displayOption === DISPLAY_EXCLUDE_PAGES) {
        return matchURLWithList(matchingUrls, false, true);
    }
    return false;
}


//Checks if the current is available (ie., if it is supported by push notifications and it is not incognito, instagram or an in app browser)
export function isBrowserAvailable(isPrivateMode) {
    return !isPrivateMode && !isSafari() && !isInstagramBrowser() && !isInAppBrowser();
}

//Detects if the browser is in private mode. Due to the latest updates, this is not working.
export function detectPrivateMode() {
    return new Promise(function (resolve) {
        let yes = function () {
            resolve(true);
        }; // is in private mode
        let not = function () {
            resolve(false);
        }; // not in private mode

        function detectChromeOpera() {
            // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
            let isChromeOpera = /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) && navigator.storage && navigator.storage.estimate;
            if (isChromeOpera) {
                navigator.storage.estimate().then(function (data) {
                    return data.quota < 120000000 ? yes() : not();
                });
            }
            return !!isChromeOpera;
        }

        function detectFirefox() {
            let isMozillaFirefox = 'MozAppearance' in document.documentElement.style;
            if (isMozillaFirefox) {
                if (indexedDB == null) yes();
                else {
                    let db = indexedDB.open('inPrivate');
                    db.onsuccess = not;
                    db.onerror = yes;
                }
            }
            return isMozillaFirefox;
        }

        function detectSafari() {
            let isSafari = navigator.userAgent.match(/Version\/([0-9\._]+).*Safari/);
            if (isSafari) {
                let testLocalStorage = function () {
                    try {
                        if (localStorage.length) not();
                        else {
                            localStorage.setItem('inPrivate', '0');
                            localStorage.removeItem('inPrivate');
                            not();
                        }
                    } catch (_) {
                        // Safari only enables cookie in private mode
                        // if cookie is disabled, then all client side storage is disabled
                        // if all client side storage is disabled, then there is no point
                        // in using private mode
                        navigator.cookieEnabled ? yes() : not();
                    }
                    return true;
                };

                let version = parseInt(isSafari[1], 10);
                if (version < 11) return testLocalStorage();
                try {
                    window.openDatabase(null, null, null, null);
                    not();
                } catch (_) {
                    yes();
                }
            }
            return !!isSafari;
        }

        function detectEdgeIE10() {
            let isEdgeIE10 = !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);
            if (isEdgeIE10) yes();
            return !!isEdgeIE10;
        }

        // when a browser is detected, it runs tests for that browser
        // and skips pointless testing for other browsers.
        if (detectChromeOpera()) return;
        if (detectFirefox()) return;
        if (detectSafari()) return;
        if (detectEdgeIE10()) return;

        // default navigation mode
        return not();
    });
}

//Tries to guess the device type (mobile|tablet|desktop) by examining the user-agent
export function _wpnGetDeviceType() {
    //Inspired by https://github.com/PoeHaH/devicedetector/blob/master/devicedetector-production.js
    let ua = navigator.userAgent.toLowerCase();
    if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
        return DEVICE_TABLET;
    } else {
        if (/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua)) {
            return DEVICE_PHONE;
        }
    }

    return DEVICE_DESKTOP;
}

//Checks if the selected storage is available
export function storageAvailable(type) {
    let storage, x;
    try {
        storage = window[type];
        x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}

//Checks the user agent and returns true if the browser is safari or firefox
export function isSafari() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.search("safari") >= 0 && ua.search("chrome") < 0) {
        return true;
    }
    return false;
}

//Checks the user agent and returns true if the browser is safari on a 16+ version that has web push enabled. In case it does not match with safari, returns true
function isSafariWithPushAvailable() {
    const safariVersionMatch = navigator.userAgent.match(/Version\/(\d+\.\d+)/);
    const safariVersion = safariVersionMatch ? parseFloat(safariVersionMatch[1]) : false;
    return navigator.userAgent.includes('Safari') && (!safariVersion || safariVersion >= 16.4);
}


//Checks the user agent and return true if the browser is instagram
function isInstagramBrowser() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.search("instagram") >= 0;
}

//Checks the user agent and return true if the browser is from in app
function isInAppBrowser() {
    const rules = [
        'WebView',
        'FBAN',
        '(iPhone|iPod|iPad)(?!.*Safari\/)',
        'Android.*wv',
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return navigator.userAgent.match(regex) !== null;
}

//Deletes a cookie by name
export function _wpnDeleteCookie(cname) {
    _wpnSetCookieInSeconds(cname, null, 0); //Explicitly set a null value.
    document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
}

//Get the value from the cookie identified by cname. Returns null if not found.
export function _wpnGetCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

function _GA4ExtractGA4MeasurementID() {
    const preferredTag = window._wpnPreferredGAProperty;
    if(preferredTag && preferredTag.startsWith('G-')) {
        return preferredTag.replace('G-', '');
    }
    const cookies = document.cookie.split('; ');
    const ga4Pattern = /^_ga_([A-Z0-9]+)$/;
    let measurementID = null;

    for (let cookie of cookies) {
        const cookieName = cookie.split('=')[0];
        const match = cookieName.match(ga4Pattern);
        if (match) {
            measurementID = match[1];
            break;
        }
    }

    return measurementID;
}

// Tipos de datos esperados: feature (string), item_name (string), item_price (float), currency (string)
export function sendEventToClientsGA4(feature, item_name, item_price, currency, event_name) {
    try {
        const measurement_id = _GA4ExtractGA4MeasurementID();
        if (measurement_id) {
            let gaData = {
                'send_to': ['G-' + measurement_id],
                'feature': feature
            };
            /*if (item_name && item_price && currency) {
                gaData.items = [{
                    'item_name': item_name,
                    'price': item_price,
                    'currency': currency
                }];
            }else{
                gaData.items = [{
                    'item_name': 'WPN article'
                }];
            }*/
            if (typeof gtagNsGA4 === 'function') {
                gtagNsGA4('event', event_name, gaData);
            } else if (typeof gtag === 'function') {
                gtag('event', event_name, gaData);
            } else {
                // Extract the GA4 measurement ID
                var gtag_script = document.createElement('script');
                gtag_script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-' + measurement_id);
                document.head.appendChild(gtag_script);

                window.dataLayer = window.dataLayer || [];

                function gtag() {
                    dataLayer.push(arguments);
                }

                gtag('js', new Date());
                gtag('config', 'G-' + measurement_id);
                gtag('event', event_name, gaData);
            }
        }
    } catch (e) {
        console.error('Error sending event to GA4', e);
    }
}


//Save a cookie in the browser with cookie name, value and expiration days
export function _wpnSetCookie(cname, cvalue, exdays) {
    _wpnSetCookieInSeconds(cname, cvalue, exdays * 86400);
}

//Save a cookie in the browser with cookie name, value and expiration in seconds
export function _wpnSetCookieInSeconds(cname, cvalue, exseconds) {
    let d = new Date();
    d.setTime(d.getTime() + (exseconds * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//LocalStorage with expiration in seconds (TTL)
export function storeInLocalStorageWithExpiration(key, content, ttl) {
    localStorage.setItem(key, content);
    localStorage.setItem(key + ':ts', Math.floor(Date.now() / 1000) + ttl);
}

//Gets the specified key from local storage while checking the expiration date (TTL)
export function getFromLocalStorageWithExpiration(key) {
    // Use the URL as the cache key to sessionStorage
    let cached = localStorage.getItem(key)
    let expiration = localStorage.getItem(key + ':ts')
    if (cached !== null && expiration !== null) {
        // it was in sessionStorage! Yay!
        // Even though 'whenCached' is a string, this operation
        // works because the minus sign converts the
        // string to an integer and it will work.
        if (Math.floor(Date.now() / 1000) < expiration) {
            return cached;
        } else {
            // We need to clean up this old key because it has expired
            localStorage.removeItem(key)
            localStorage.removeItem(key + ':ts')
        }
    }

    return null;
}

//Performs a cached fetch by storing the contents of the request in the localstorage
//Example of using options as an object for tuning expiration and cache key as well as other attributes of the fetch:
// let options = {mode: 'same-origin', seconds: 3 * 60}
export function cachedFetch(url, options) {
    let cacheKey = url;
    let expiry = 300 // 5 min default
    if (typeof options === 'number') {
        expiry = options;
        options = undefined;
    } else if (typeof options === 'object') {
        // I hope you didn't set it to 0 seconds
        expiry = options.seconds || expiry;
        cacheKey = options.cacheKey || url;
    }

    let cached = getFromLocalStorageWithExpiration(cacheKey);
    if (cached !== null) {
        let response = new Response(new Blob([cached]));
        return Promise.resolve(response);
    }

    return fetch(url, options).then(function (response) {
        // let's only store in cache if the content-type is
        // JSON or something non-binary
        if (response.status === 200) {
            let ct = response.headers.get('Content-Type')
            if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
                // There is a .json() instead of .text() but
                // we're going to store it in sessionStorage as
                // string anyway.
                // If we don't clone the response, it will be
                // consumed by the time it's returned. This
                // way we're being un-intrusive.
                response.clone().text().then(function (content) {
                    storeInLocalStorageWithExpiration(cacheKey, content, expiry);
                });
            }
        }
        return response;
    })
}

//Send event to client's Google Analytics if available
export function sendEventToClientsGA(dataLayerEvent, eventCategory, eventAction, eventLabel = null, eventValue = null) {
    const preferredTag = window._wpnPreferredGAProperty;
    const preferredTagNotSet = preferredTag == null || !preferredTag.startsWith('G-');
    if (typeof gtagNsGA4 === 'function') {
        //Tiendanube custom implementation for GA4 (not documented anywhere)
        //They replaced the normal gtag function with a custom one to avoid collisions
        if (preferredTagNotSet) {
            gtagNsGA4('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
                'value': eventValue,
                'layerEvent': dataLayerEvent,
                'source': 'wpn',
            });
        } else {
            gtagNsGA4('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
                'value': eventValue,
                'layerEvent': dataLayerEvent,
                'source': 'wpn',
                'send_to': preferredTag
            });
        }
    } else if (typeof gtag === 'function') {
        //GA4 events @see https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
        //ROUTING https://developers.google.com/tag-platform/gtagjs/routing?hl=es
        if (preferredTagNotSet) {
            gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
                'value': eventValue,
                'layerEvent': dataLayerEvent,
                'source': 'wpn',
            });
        } else {
            gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
                'value': eventValue,
                'layerEvent': dataLayerEvent,
                'source': 'wpn',
                'send_to': preferredTag
            });
        }
    } else if (typeof ga === "function") {
        if (typeof ga.getAll === "function") {
            //Reads the preferred analytics property (optional)
            let tracker = getPreferredTracker(ga.getAll(), window._wpnPreferredGAProperty || null);
            if (tracker && typeof tracker.send === "function") {
                tracker.send('event', eventCategory, eventAction, eventLabel, {
                    nonInteraction: true
                });
            } else { //Fallback in case ga.getAll() returns [] (ex: mundo del juguete)
                ga('send', 'event', eventCategory, eventAction, eventLabel, {
                    nonInteraction: true
                });
            }
        } else {
            ga('send', 'event', eventCategory, eventAction, eventLabel, {
                nonInteraction: true
            });
        }
    }
    if (typeof dataLayer === 'object') {
        dataLayer.push({
            'event': dataLayerEvent,//'wpnTriggerClicked',
            'wpnEventCategory': eventCategory,
            'wpnEventAction': eventAction,
            'wpnEventLabel': eventLabel,
        });
    }
}

//Returns the first or preferred tracking, if the identifier is found in the tracking list
function getPreferredTracker(trackers, identifier) {
    let foundTracker = null;
    for (let index = 0; index < trackers.length; index++) {
        let tracker = trackers[index];
        if (tracker && typeof tracker.send === 'function') {
            if (identifier === null) { //No identifier, return the first tracker
                return tracker;
            }
            if (foundTracker === null) { //Set the first to be returned as fallback if identifier is not found
                foundTracker = tracker;
            }
            if (tracker.model && tracker.model.data && tracker.model.data.ea && tracker.model.data.ea[':trackingId']) {
                if (identifier === tracker.model.data.ea[':trackingId']) {
                    return tracker;
                }
            }
        }
    }
    return foundTracker;
}